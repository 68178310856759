export enum Page {
  home,
  trafficInfo,
  positionRequest,
  trainOnTimeAdmin
}

export enum PageName{
  "" = 0,
  "trafikinfo" = 1,
  "positionsforfragning" = 2,
  "tagitidadmin" = 3
}
