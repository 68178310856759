import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import { Page } from "../../../enums/core-enums";
import { AuthService } from "../../../services/auth.service";
import { MessageService } from "../../../services/message.service";
import {NgForOf, NgIf} from "@angular/common";
import {environment} from "../../../../environments/environment";

@Component({
  selector: "app-drop-down",
  templateUrl: "./drop-down.component.html",
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NgForOf,
    NgIf
  ],
  styleUrls: ["./drop-down.component.scss"]
})
export class DropDownComponent implements OnInit {
  menuItems = [
    {
      displayName: "Trafikinfo",
      path: "trafikinfo",
      page: Page.trafficInfo,
      redirect: false,
      protected: false
    },
    {
      displayName: "Tåg i Tid Admin",
      path: environment.trainOnTimeAdminUrl,
      page: Page.trainOnTimeAdmin,
      redirect: true,
      protected: true
    },
    {
      displayName: "Översikt",
      path: "",
      page: Page.home,
      redirect: false,
      protected: false
    }
  ];

  @Output() close: EventEmitter<Page> = new EventEmitter();
  constructor(
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.menuItems.sort((a, b) => {
      return a.displayName
        .toLowerCase()
        .localeCompare(b.displayName.toLowerCase());
    });
  }

  onClick(item) {
    if (
      !this.messageService.hasChanges() ||
      item.page == Page.trafficInfo ||
      confirm(
        "Du har osparade ändringar. Vill du verkligen stänga meddelandet?"
      )
    ) {
      if (item.redirect) {
        window.open(item.path, "_blank")
      } else {
        this.router.navigateByUrl(item.path);
      }
      this.close.emit(item.page);
    }
  }

  hasGroups() {
    return this.authService.hasReadWriteGroups();
  }

  shouldShow(menuItem) {
    return (
      this.authService.loggedIn && (!menuItem.protected || this.hasGroups())
    );
  }
}
