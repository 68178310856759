import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Observable, switchMap } from "rxjs";
import {environment} from "../../environments/environment";
import {protectedResourceMap} from "../app.module";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const activeAccount = this.msalService.instance.getActiveAccount()
    if (activeAccount) {
      const scope = this.getScope(req.url)
      return this.msalService.acquireTokenSilent({
        account: activeAccount, scopes: [scope]
      })
        .pipe(
          switchMap((authRequest) => {
            const clonedReq = req.clone({
              headers: req.headers.set("Authorization",`Bearer ${authRequest.accessToken}`)
            })
            return next.handle(clonedReq)
          }))
    }
    return next.handle(req)
  }


  getScope(reqUrl: string) : string {
    let splitUrl: string[]
    if (reqUrl.includes(environment.apiBaseUrl)) {
      splitUrl = reqUrl.split("/api/")
      return protectedResourceMap.get(splitUrl[0])![0]
    } else if (reqUrl.includes(environment.graphUrl)) {
      splitUrl = reqUrl.split("v1.0")
      console.log(splitUrl)
      console.log(splitUrl[0] + "v1.0")
      return protectedResourceMap.get(splitUrl[0] + "v1.0")![0]
    }else{
      return "";
    }
  }
}
