import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import localeSv from "@angular/common/locales/sv";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MenuComponent} from "./core/menu/menu.component";
import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch} from "@angular/common/http";
import {MsalModule} from "@azure/msal-angular";
import {BrowserCacheLocation, InteractionType, PublicClientApplication} from "@azure/msal-browser";
import {environment} from "../environments/environment";
import {AuthInterceptor} from "./interceptors/AuthInterceptor";
import {MessageListComponent} from "./pages/traffic-information/message-list/message-list.component";
import {FilterComponent} from "./pages/traffic-information/message-list/filter/filter.component";
import {MatIconModule} from "@angular/material/icon";
import {CalendarModule} from "primeng/calendar";
import {PaginatorModule} from "primeng/paginator";
import {MatButtonModule} from "@angular/material/button";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {UserInfoComponent} from "./pages/user-info/user-info.component";
import {UserInfoFilterPipe} from "./shared/userinfofilter.pipe";
import {ProgressSpinnerComponent} from "./shared/progress-spinner/progress-spinner.component";
import {ErrorBoxComponent} from "./shared/error-box/error-box.component";
import {HeaderComponent} from "./pages/traffic-information/message/header/header.component";
import {AppMessageFormComponent} from "./pages/traffic-information/message/message-form/app-message-form/app-message-form.component";
import {MessageFormComponent} from "./pages/traffic-information/message/message-form/message-form.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MessageComponent} from "./pages/traffic-information/message/message.component";
import {WebMessageFormComponent} from "./pages/traffic-information/message/message-form/web-message-form/web-message-form.component";
import {RestrictedComponent} from "./pages/restricted/restricted.component";
import {TrafficInformationComponent} from "./pages/traffic-information/traffic-information.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {registerLocaleData} from "@angular/common";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

// export function loggerCallback(logLevel, message, piiEnabled) {
//   console.log("client logging\n" + message);
// }

registerLocaleData(localeSv, "sv");

export const protectedResourceMap: Map<string,string[]> = new Map([
  [environment.apiBaseUrl, [environment.scopes]],
  [environment.graphUrl, ["user.read"]]
]);

@NgModule({
  declarations: [
    AppComponent,
    MessageListComponent,
    FilterComponent,
    UserInfoComponent,
    UserInfoFilterPipe,
    ProgressSpinnerComponent,
    ErrorBoxComponent,
    HeaderComponent,
    AppMessageFormComponent,
    MessageFormComponent,
    MessageComponent,
    WebMessageFormComponent,
    RestrictedComponent,
    TrafficInformationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MenuComponent,
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
        auth: {
          clientId: environment.clientID,
          authority: environment.loginUrl + environment.tenantID,
          redirectUri: environment.loginRedirect,
          postLogoutRedirectUri: environment.url,
          navigateToLoginRequestUrl: true
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE // set to true for IE 11
        },
        system: {
          // loggerOptions: {
          //   loggerCallback: loggerCallback,
          //   piiLoggingEnabled: true
          // }
        }
      }),
      {
        interactionType: InteractionType.Redirect //MSAL Guard Configuration
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: protectedResourceMap // MSAL Interceptor Configuration

      }),
    MatIconModule,
    CalendarModule,
    PaginatorModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: "sv-SE"},
  ],
  exports: [
    MessageComponent,
    MessageListComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
