// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: false,
  test: true,
  url: "https://test-amigo.stockholmstag.sj.se",
  loginRedirect: "https://test-amigo.stockholmstag.sj.se/signin-oidc",
  apiBaseUrl: "https://test-api.stockholmstag.sj.se/public/messagesapi",
  graphUrl: "https://graph.microsoft.com/v1.0",
  getMessagesApiSuffix: "/api/Message/GetMessages",
  getToTMessagesApiSuffix: "/api/Message/GetToTMessages",
  createMessageApiSuffix: "/api/Message/CreateMessage",
  updateMessageApiSuffix: "/api/Message/UpdateMessage",
  scopes: "api://f782d600-f96c-42fd-85c2-fd4d7e1f1926/access_as_user",
  getMessagesByIdApiSuffix: "/api/Message/GetMessagesById",
  clientID: "7cf05aa3-2cb8-42e3-8772-e8715da7f141",
  tenantID: "2146c4ae-6471-40a8-b821-2774c8057963",
  loginUrl: "https://login.microsoftonline.com/",
  trainOnTimeAdminUrl: "https://app-sjp-tot-test.azurewebsites.net/admin",
  readWriteGroups: ["9ec4358d-6488-409a-97b4-3f43a6505aba"]
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
