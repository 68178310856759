import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./core/home/home.component";
import {MsalGuard} from "@azure/msal-angular";
import {MessageComponent} from "./pages/traffic-information/message/message.component";
import {UserInfoComponent} from "./pages/user-info/user-info.component";
import {RestrictedComponent} from "./pages/restricted/restricted.component";
import {TrafficInformationComponent} from "./pages/traffic-information/traffic-information.component";

const routes: Routes = [
  { path: "", component: HomeComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: "trafikinfo", component: TrafficInformationComponent, pathMatch: "full", canActivate: [MsalGuard] },
  {
    path: "trafikinfoPopup/:id",
    component: MessageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "signin-oidc",
    component: UserInfoComponent
  },
  {
    path: "restrictionTest",
    component: TrafficInformationComponent,
    canActivate: [MsalGuard]
  },
  {
    path: "restricted",
    component: RestrictedComponent,
    canActivate: []
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
